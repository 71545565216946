@import './themes/generated/variables.base.scss';

.app {
	background-color: darken($base-bg, 5);
	display: flex;
	height: 100%;
	width: 100%;

	.content {
		line-height: 1.5;
		flex-grow: 1;

		h2 {
			font-size: 30px;
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	.container {
		height: 100%;
		flex-direction: column;
		display: flex;
	}

	.layout-body {
		flex: 1;
		min-height: 0;
	}

	.content-block {
		margin-left: 40px;
		margin-right: 40px;
		margin-top: 8px;
		margin-bottom: 8px;
		font-size: 12px;
	}
}

.side-nav-outer-toolbar .dx-drawer {
	height: calc(100% - 56px);
}

.screen-x-small .content-block {
	margin-left: 20px;
	margin-right: 20px;
}

.responsive-paddings {
	padding: 20px;

	.screen-large & {
		padding: 40px;
	}
}

.dx-card.wide-card {
	border-radius: 0;
	margin-left: 0;
	margin-right: 0;
	border-right: 0;
	border-left: 0;
}

$side-panel-min-width: 60px;

html,
body {
	margin: 0px;
	min-height: 100%;
	height: 100%;
}

#root {
	height: 100%;
}

* {
	box-sizing: border-box;
}

.dx-datagrid .dx-row > td {
	padding: 8px 10px;
	font-size: 12px;
	line-height: 14px;
}

.dx-menu .dx-menu-item {
	font-size: 12px;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
	font-size: 12px;
	height: 36px;
	line-height: 24px;
}

.dx-datagrid .dx-datagrid-table .dx-header-row > td {
	padding-top: 10px;
	padding-bottom: 10px;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content .dx-icon,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content .dx-icon {
	width: 16px;
	height: 16px;
}

.dx-datebox-datetime .dx-dropdowneditor-icon {
	font: 16px/16px DXIcons;
	width: 16px;
}

.dx-datagrid .dx-toolbar .dx-toolbar-items-container {
	height: 36px;
}

.dx-field > .dx-field-label {
	width: 70%;
}

.dx-field > .dx-field-value {
	width: 30%;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content .dx-icon,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content .dx-icon {
	margin-top: -10px;
}

a.link {
	text-decoration: none;
	color: black;
}

a.link:hover {
	text-decoration: underline;
}

.dx-row-focused a.link {
	color: white;
}

.dx-cell-focus-disabled a.link {
	color: black;
}

.dx-datagrid-filter-panel {
	padding: 5px 16px;
}

.gridView {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 10px;
}

.modelCard {
	border-radius: 10px;
	box-sizing: content-box;
	height: 150px;
	background-color: #ffffff;
	cursor: pointer;
	text-decoration: none;
	display: flex;
	align-items: center;
	max-width: 400px;
	box-shadow:
		rgba(0, 0, 0, 0.12) 0px 1px 3px,
		rgba(0, 0, 0, 0.24) 0px 1px 2px;
	transition: 0.4s ease;
}

.modelCard:hover {
	box-shadow:
		rgba(0, 0, 0, 0.19) 0px 10px 20px,
		rgba(0, 0, 0, 0.23) 0px 6px 6px;
	transform: scale(1.02);
	background-color: #f7ede5;
	background-image: radial-gradient(#fc8f00 0.5px, #f7ede5 0.5px);
	background-size: 10px 10px;
}

.modelCard .textWrapper {
	margin: 20px;
	padding: 5px;
	color: black;
}

.menu-container {
	.userManual {
		border-bottom: 1px solid #515159;
		display: grid;
		justify-content: flex-start;
		align-items: center;
		padding-left: 10px;

		.dx-button-text {
			text-transform: capitalize;
		}
		.dx-icon {
			font-size: 26px !important;
		}
		.dx-button-content {
			display: flex;
			gap: 11px;
		}
	}

	.search-container {
		border-bottom: 1px solid #515159;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 16px;
		height: 50px;

		.dx-icon {
			font-size: 26px !important;
			float: left;
		}

		.search-input {
			border: none;
			background-color: transparent;
			width: 80%;
			height: 30px;
			float: right;
			margin-left: 12px;
			color: white;
		}

		.search-input:focus {
			outline: none;
		}
	}
}

.warningList div {
	color: red;
	font-size: 14px;
	padding: 0px 10px 4px 2px;
}

.reportContainer {
	display: flex;
	flex-direction: column;
	height: 100dvh;
}
.reportClass {
	display: flex;
	flex-grow: 1;
	height: 100%;
}

.popup-message {
	padding: 10px 0;
	display: flex;
	justify-content: center;
	gap: 50px;
	align-items: center;
	border-radius: 8px;
	background-color: #f6f6f6;
	font-weight: bold;
}

.extension {
	font-size: 12px;
	font-weight: lighter;
	font-style: italic;
	color: #c5c5c5;
}

.datePickerAudit {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	border: 1px solid lightgrey;
	border-radius: 10px;
	padding: 10px;
	font-size: 12px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dx-datagrid-headers .dx-datagrid-text-content {
	pointer-events: none;
}

.flashButton {
	background-color: #ff794e;
	animation: flash 2s infinite;
}

@keyframes flash {
	0%,
	100% {
		background-color: #ff794e;
	}
	50% {
		background-color: #ffffff;
	}
}

.saveFilterInput {
	display: flex;
	align-items: center;
}

.saveFilterInput > * {
	width: 80% 20%;
	height: 50px;
}

.savedFilters-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.savedFilters-container .filter-item {
	display: flex;
	align-items: center;
	gap: 10px;
}

.savedFilters-container .logic-operator {
	background-color: #f8d7da;
	color: #721c24;
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 0.9em;
}

.savedFilters-container .filter-details {
	display: flex;
	align-items: center;
	gap: 5px;
}

.savedFilters-container .field {
	background-color: #f443364d;
	color: #721c24;
	padding: 5px 10px;
	border-radius: 5px;
}

.savedFilters-container .operation {
	background-color: #d4edda;
	color: #155724;
	padding: 5px 10px;
	border-radius: 5px;
}

.savedFilters-container .value {
	background-color: #e2e3e5;
	color: #383d41;
	padding: 5px 10px;
	border-radius: 5px;
}

.dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable {
	resize: both;
	overflow: auto;
	min-width: 40dvh;
	min-height: 40dvh;
	max-width: 90dvw;
	max-height: 80dvh;
}

.dx-popup-draggable .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-bottom {
    position: fixed;
    bottom: 0;
}
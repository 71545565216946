@import '../../themes/generated/variables.base.scss';

.user-info {
	display: flex;
	align-items: center;
	padding: 9px 0px 9px 12px;

	.dx-toolbar-menu-section & {
		padding: 10px 6px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.image-container {
		overflow: hidden;
		border-radius: 50%;
		height: 30px;
		width: 30px;
		margin: 0 4px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
	}

	.user-name {
		font-size: 14px;
		color: white;
		margin: 0 9px;
	}
}

.user-panel {
	.dx-list-item .dx-icon {
		vertical-align: middle;
		color: $base-text-color;
		margin-right: 16px;
	}
	.dx-rtl .dx-list-item .dx-icon {
		margin-right: 0;
		margin-left: 16px;
	}
}

.dx-context-menu.user-menu.dx-menu-base {
	&.dx-rtl {
		.dx-submenu .dx-menu-items-container .dx-icon {
			margin-left: 16px;
		}
	}
	.dx-submenu .dx-menu-items-container .dx-icon {
		margin-right: 16px;
	}
	.dx-menu-item .dx-menu-item-content {
		padding: 3px 15px 4px;
	}
}

.dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
	padding-left: 4px;
	padding-right: 4px;
}

@import '../../themes/generated/variables.base.scss';
@import '../../dx-styles.scss';

.header-component {
	flex: 0 0 auto;
	z-index: 1;

	.dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
		color: $base-accent;
	}

	@media only screen and (min-width: 768px) {
		display: none;
	}
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
	padding: 0 40px;

	.screen-x-small & {
		padding: 0 20px;
	}
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
	width: $side-panel-min-width;
	text-align: center;
	padding: 0;
	// background-color: rgba(0, 0, 0, 0.233);
}

.header-title .dx-item-content {
	padding: 0;
	margin: 0;
}

.dx-theme-generic {
	.dx-toolbar {
		padding: 10px 0;
	}

	.user-button > .dx-button-content {
		padding: 3px;
	}
}

.map-container {
	position: relative;
	width: 100%;
	height: 100%;
}

.infoBox {
	overflow: visible !important;
	margin-left: -50px !important;
	margin-top: -50px !important;
}

.dx-button-mode-text.dx-button-default .dx-icon {
	color: #000;
	transform: scale(1.5);
}

.customClusterer {
	width: 80px;
	height: 80px;
	background: #f17272;
	display: grid;
	border: 10px solid #ffa9a9;
	border-radius: 50%;
	place-items: center;
}

.customClusterer p {
	font-size: 1rem;
	margin: 0px;
}

.imageContainer {
	width: 200px;
	height: auto;
	overflow: hidden;
}

.imageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.legend {
	padding: 10px 0 10px 0;
  	display: grid;
}

.legend-item {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	gap: 2px;
}

.legend-item-inner {
	display: flex;
}

.color-box {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 4px;
}
